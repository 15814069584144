import { Callout } from '../callout'
import { TypographyCaps, TypographyParagraph } from '../typography'
import clsx from 'clsx'

const InputField = ({
	name,
	id,
	type,
	placeholder,
	helper,
	isValid,
	isError,
	isInactive = false,
	icon,
	onIconClick,
	additionalClasses = '',
	containerWidth = 'w-80',
	displayName = true,
	displayRequired = false,
	lines = 1,
	value = '',
	onChange = () => {},
}: {
	name: string
	id?: string
	type?: string
	placeholder: string
	helper?: string
	isValid?: boolean
	isError?: boolean
	isInactive?: boolean
	icon?: React.ReactNode
	onIconClick?: () => void
	additionalClasses?: string
	containerWidth?: string
	displayName?: boolean
	displayRequired?: boolean
	lines?: number
	value?: string | number
	onChange?: (e: any) => void
}) => {
	const inputId = id ? id : name
	return (
		<div className={containerWidth}>
			{displayName && (
				<label
					htmlFor={name}
					className="mb-1 block leading-[14px] text-grey400 dark:text-grey500"
				>
					<TypographyCaps size="small">{`${name}${displayRequired ? ' *' : ''}`}</TypographyCaps>
				</label>
			)}
			<div className="mt group relative">
				{lines == 1 ? (
					<input
						type={type ? type : name}
						name={inputId}
						id={name}
						disabled={isInactive}
						className={clsx(
							'block w-full rounded-md border-0 px-4 py-3 font-abc text-paragraphMedium outline-0 ring-1 ring-inset',
							// DROP SHADOW
							// 'drop-shadow-menu dark:drop-shadow-none',
							// Default
							!isValid &&
								!isError &&
								'text-textPrimaryLight ring-textInactiveLight dark:text-textPrimaryDark dark:ring-textInactiveDark',
							// Inactive
							isInactive &&
								'cursor-not-allowed truncate bg-uiSecondaryLight text-textPrimaryLight placeholder:text-textSecondaryLight dark:bg-black dark:text-textPrimaryDark dark:placeholder:text-textSecondaryDark',
							!isInactive &&
								'cursor-pointer bg-white text-grey400 placeholder:text-grey400 dark:bg-dark dark:text-grey600 dark:placeholder:text-grey600',
							// Validated
							!isInactive && isValid && 'ring-green',
							// hasError
							!isInactive && isError && 'ring-red',
							// Hover
							!isInactive &&
								'hover:ring-textSecondaryLight dark:hover:ring-textSecondaryDark',
							// Focus
							!isInactive &&
								'focus:text-textPrimaryLight focus:placeholder-textPrimaryLight focus:ring-textPrimaryLight dark:focus:text-textPrimaryDark dark:focus:placeholder-textPrimaryDark dark:focus:ring-textPrimaryDark',
							// Icon
							icon && 'pr-11',
							additionalClasses,
						)}
						placeholder={placeholder}
						aria-describedby={`${name}-description`}
						value={value}
						onChange={onChange}
					/>
				) : (
					<textarea
						rows={lines}
						name={inputId}
						id={name}
						disabled={isInactive}
						className={clsx(
							'block w-full rounded-md border-0 px-4 py-3 font-abc text-paragraphMedium outline-0 ring-1 ring-inset',
							// DROP SHADOW
							//  drop-shadow-menu dark:drop-shadow-none',
							// Default
							!isValid &&
								!isError &&
								'text-textPrimaryLight ring-textInactiveLight placeholder:text-textSecondaryLight dark:text-textPrimaryDark dark:ring-textInactiveDark',
							// Inactive
							isInactive &&
								'cursor-not-allowed bg-uiSecondaryLight text-textPrimaryLight placeholder:text-textSecondaryLight dark:bg-black dark:text-textPrimaryDark dark:placeholder:text-textSecondaryDark',
							!isInactive &&
								'cursor-pointer bg-white text-grey400 placeholder:text-grey400 dark:bg-black dark:text-grey600 dark:placeholder:text-grey600',
							// Validated
							!isInactive && isValid && 'ring-green',
							// hasError
							!isInactive && isError && 'ring-red',
							// Hover
							!isInactive &&
								'hover:ring-textSecondaryLight dark:hover:ring-textSecondaryDark',
							// Focus
							!isInactive &&
								'focus:text-textPrimaryLight focus:placeholder-textPrimaryLight focus:ring-textPrimaryLight dark:focus:text-textPrimaryDark dark:focus:placeholder-textPrimaryDark dark:focus:ring-textPrimaryDark',
							additionalClasses,
						)}
						placeholder={placeholder}
						aria-describedby={`${name}-description`}
						value={value}
						onChange={onChange}
					/>
				)}
				{icon && (
					<div
						className={clsx(
							'absolute inset-y-0 right-0 flex items-center pr-3',
							isValid &&
								'text-green group-focus:text-textPrimaryLight dark:group-focus:text-textPrimaryDark',
							isError &&
								'text-red group-focus:text-textPrimaryLight dark:group-focus:text-textPrimaryDark',
							isInactive &&
								!onIconClick &&
								'text-textInactiveLight dark:text-textInactiveDark',
							onIconClick && 'text-textPrimaryLight dark:text-textPrimaryDark',
						)}
						onClick={onIconClick}
					>
						{icon}
					</div>
				)}
			</div>
			{helper && isError ? (
				<Callout additionalClasses="mt-4" variant="error" content={helper} />
			) : helper ? (
				<TypographyParagraph
					size="xSmall"
					additionalClasses={clsx(
						'mt-2',
						isError
							? 'text-red dark:text-red'
							: 'text-textSecondaryLight dark:text-textSecondaryDark',
					)}
				>
					{helper}
				</TypographyParagraph>
			) : null}
		</div>
	)
}

export default InputField
